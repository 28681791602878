// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AutomaticFormCreation from "../../blocks/AutomaticFormCreation/src/AutomaticFormCreation";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SmartCategorisation from "../../blocks/SmartCategorisation/src/SmartCategorisation";
import OrganisationHierarchy from "../../blocks/OrganisationHierarchy/src/OrganisationHierarchy";
import DocumentDistribution from "../../blocks/documentdistribution/src/DocumentDistribution";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import SapBpModuleConnection from "../../blocks/SapBpModuleConnection/src/SapBpModuleConnection";
import CfIntegrateWithExistingInvoiceGrnUploadingPlatform from "../../blocks/CfIntegrateWithExistingInvoiceGrnUploadingPlatform/src/CfIntegrateWithExistingInvoiceGrnUploadingPlatform";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import ToleranceEditorBackend from "../../blocks/ToleranceEditorBackend/src/ToleranceEditorBackend";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import QuantityMatching from "../../blocks/QuantityMatching/src/QuantityMatching";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import QuantityMatching2 from "../../blocks/QuantityMatching2/src/QuantityMatching2";
import Notifications from "../../blocks/notifications/src/Notifications";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import Analytics from "../../blocks/analytics/src/Analytics";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import SapFicoModuleConnection from "../../blocks/SapFicoModuleConnection/src/SapFicoModuleConnection";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import DataStorage from "../../blocks/DataStorage/src/DataStorage";
import BhimUpiIntegration2 from "../../blocks/BhimUpiIntegration2/src/BhimUpiIntegration2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import SapMmModuleConnection from "../../blocks/SapMmModuleConnection/src/SapMmModuleConnection";
import Groups from "../../blocks/Groups/src/Groups";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import CfIntegrateWithSap2 from "../../blocks/CfIntegrateWithSap2/src/CfIntegrateWithSap2";
import SapHcmModuleConnectrion from "../../blocks/SapHcmModuleConnectrion/src/SapHcmModuleConnectrion";
import DuplicateDetection from "../../blocks/DuplicateDetection/src/DuplicateDetection";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import OcropticalCharacterRecognition from "../../blocks/OcropticalCharacterRecognition/src/OcropticalCharacterRecognition";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";



const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SmartCategorisation:{
 component:SmartCategorisation,
path:"/SmartCategorisation"},
OrganisationHierarchy:{
 component:OrganisationHierarchy,
path:"/OrganisationHierarchy"},
DocumentDistribution:{
 component:DocumentDistribution,
path:"/DocumentDistribution"},
TaxCalculator:{
 component:TaxCalculator,
path:"/TaxCalculator"},
SapBpModuleConnection:{
 component:SapBpModuleConnection,
path:"/SapBpModuleConnection"},
CfIntegrateWithExistingInvoiceGrnUploadingPlatform:{
 component:CfIntegrateWithExistingInvoiceGrnUploadingPlatform,
path:"/CfIntegrateWithExistingInvoiceGrnUploadingPlatform"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
ToleranceEditorBackend:{
 component:ToleranceEditorBackend,
path:"/ToleranceEditorBackend"},
Customform:{
 component:Customform,
path:"/Customform"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
QuantityMatching:{
 component:QuantityMatching,
path:"/QuantityMatching"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
QuantityMatching2:{
 component:QuantityMatching2,
path:"/QuantityMatching2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PdfEdit:{
 component:PdfEdit,
path:"/PdfEdit"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
SapFicoModuleConnection:{
 component:SapFicoModuleConnection,
path:"/SapFicoModuleConnection"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
BhimUpiIntegration2:{
 component:BhimUpiIntegration2,
path:"/BhimUpiIntegration2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
SapMmModuleConnection:{
 component:SapMmModuleConnection,
path:"/SapMmModuleConnection"},
Groups:{
 component:Groups,
path:"/Groups"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
CfIntegrateWithSap2:{
 component:CfIntegrateWithSap2,
path:"/CfIntegrateWithSap2"},
SapHcmModuleConnectrion:{
 component:SapHcmModuleConnectrion,
path:"/SapHcmModuleConnectrion"},
DuplicateDetection:{
 component:DuplicateDetection,
path:"/DuplicateDetection"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
OcropticalCharacterRecognition:{
 component:OcropticalCharacterRecognition,
path:"/OcropticalCharacterRecognition"},
ProjectNotes:{
 component:ProjectNotes,
path:"/ProjectNotes"},

  Home: {
component:Analytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
